import styles from "./Spinner.module.css";

interface SpinnerProps {
  size: number;
}

export const Spinner = ({ size }: SpinnerProps) => {
  return (
    <div
      className={styles.spinner}
      style={{
        height: `${size}px`,
        width: `${size}px`,
        minHeight: `${size}px`,
        minWidth: `${size}px`,
      }}
    ></div>
  );
};
